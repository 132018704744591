var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.question)?_c('div',{staticClass:"chatarea"},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"pushx",attrs:{"size":"30","color":"#F2F7FD"}},[(
          _vm.question.owner.avatar &&
            _vm.$user.getCurrentUser().id === _vm.question.owner.id
        )?_c('v-img',{attrs:{"src":_vm.$root.asseturl + _vm.question.owner.avatar.private_hash}}):(_vm.$user.getCurrentUser().id === _vm.question.owner.id)?_c('div',{staticClass:"primary--text font-size-h4",staticStyle:{"text-transform":"uppercase","width":"100%"}},[_vm._v(" "+_vm._s(_vm.question.owner.first_name.charAt(0))+" ")]):_c('div',{staticClass:"primary--text font-size-h4",staticStyle:{"text-transform":"uppercase","width":"100%"}},[_vm._v(" D ")])],1),_c('div',{staticClass:"msg mb-3",class:'local primary'},[_vm._v(" "+_vm._s(_vm.question.question)+" ")])],1),(_vm.question.status == 'answered')?_c('div',{staticClass:"d-flex justify-center mb-3 primary--text"},[(_vm.question.doctor.doctor_id)?_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t('QUES.answer.by', {
          name: ((_vm.question.doctor.doctor_id.first_name) + " " + (_vm.question.doctor.doctor_id.last_name))
        })
      )}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('QUES.answer.default'))}})]):_vm._e(),(_vm.question.status == 'answered')?_c('div',{staticClass:"d-flex justify-end"},[(
        _vm.$user.getCurrentUser().role.id === 3 &&
          _vm.question.doctor.doctor_id.id === _vm.$doctor.getCurrentDoctor().id
      )?_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","height":"30px","width":"30px","elevation":"0","color":"rgba(165, 203, 239, 0.20)"},on:{"click":function($event){_vm.doedit = !_vm.doedit}}},[_c('unicon',{staticStyle:{"margin-right":"auto","margin-left":"auto"},attrs:{"name":"edit-alt","fill":"#0971CE","height":"15"}})],1):_vm._e(),_c('div',{staticClass:"msg mb-3 pushx",class:'remote green'},[_vm._v(" "+_vm._s(_vm.question.answer)+" ")]),_c('v-avatar',{attrs:{"size":"30","color":"#F2F7FD"}},[(_vm.question.doctor.doctor_id && _vm.question.doctor.doctor_id.avatar)?_c('v-img',{attrs:{"src":_vm.$root.asseturl + _vm.question.doctor.doctor_id.avatar.private_hash}}):_c('div',{staticClass:"primary--text font-size-h4",staticStyle:{"text-transform":"uppercase","width":"100%"}},[(_vm.question.doctor.doctor_id)?_c('span',[_vm._v(_vm._s(_vm.question.doctor.doctor_id.first_name.charAt(0)))]):_c('span',[_vm._v("D")])])],1)],1):_vm._e(),(_vm.$user.getCurrentUser().role.id === 3)?[(_vm.question.status == 'notanswered' || _vm.doedit)?_c('div',{staticClass:"mb-2"},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{staticClass:"mb-2",attrs:{"auto-grow":"","rules":_vm.detailsRules,"rows":"2","solo":"","flat":"","label":"Write your answer here, you can edit it later"},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}}),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.issending || !_vm.valid,"loading":_vm.issending,"rounded":"","color":"green","block":""},on:{"click":_vm.postAnswer}},[_vm._v("Post Answer")])],1)],1):_vm._e()]:_vm._e(),(_vm.question.rating)?_c('div',{staticClass:"d-flex flex-column mb-5 align-items-start"},[_c('v-rating',{attrs:{"value":_vm.question.rating,"readonly":""}})],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }