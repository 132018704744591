<template>
  <v-container class="my-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col
        v-if="!$vuetify.breakpoint.mobile"
        cols="12"
        sm="3"
        class="my-0 py-0 px-2"
      >
        <v-card
          :disabled="isloading"
          class="pa-2 my-0 mx-2 rounded-lg dborder"
          flat
          style="min-width:20vw"
        >
          <template v-if="$user.getCurrentUser().role.id === 4">
            <div
              @click="tab = 0"
              class="mb-1 rounded d-flex align-center px-3 font-weight-medium text-uppercase"
              :class="
                tab === 0 ? 'yellow darken-3 white--text' : 'primary--text'
              "
              style="height:36px; cursor:pointer"
              :style="tab === 0 ? '' : 'background-color: rgb(242, 247, 253);'"
            >
              {{ $t("QUES.tabs.explore") }}
            </div>
            <div
              @click="tab = 1"
              class="mb-1 rounded d-flex align-center px-3 font-weight-medium text-uppercase"
              :class="
                tab === 1 ? 'yellow darken-3 white--text' : 'primary--text'
              "
              style="height:36px; cursor:pointer"
              :style="tab === 1 ? '' : 'background-color: rgb(242, 247, 253);'"
            >
              {{ $t("QUES.myQ") }}
            </div>

            <div class="d-flex flex-column">
              <div class="primary--text mt-3 text-headline font-weight-bold">
                {{ $t("QUES.filter") }}
              </div>
              <div style="min-width: fit-content" class=""></div>
              <v-chip-group
                column
                :key="tab"
                v-if="tab === 0"
                v-model="category"
              >
                <v-chip
                  small
                  color="#d2e1f2"
                  class="primary--text"
                  active-class="white--text yellow darken-3"
                  filter
                  v-for="item in departments"
                  :key="item.id"
                >
                  <v-avatar
                    style="
                    min-width: 18px !important;
                    min-height: 18px !important;
                  "
                    height="18px"
                    width="18px"
                    max-height="18px"
                    max-width="18px"
                    color="white"
                    left
                  >
                    <v-img
                      contain
                      height="10"
                      v-if="item.avatar"
                      :src="asseturl + item.avatar.private_hash"
                    ></v-img>
                    <div
                      v-else
                      style="text-transform: uppercase; width: 100%"
                      class="primary--text font-size-h4"
                    >
                      {{ item.name.charAt(0) }}
                    </div>
                  </v-avatar>
                  {{ $util.getTranslation(item).name }}
                </v-chip>
              </v-chip-group>
              <v-chip-group :key="tab" v-else v-model="category">
                <v-chip
                  small
                  color="#d2e1f2"
                  class="primary--text"
                  active-class="white--text yellow darken-3"
                  filter
                  v-for="item in statusfilters"
                  :key="item.id"
                >
                  {{ item.name }}
                </v-chip>
              </v-chip-group>
            </div>
          </template>
          <template v-else-if="$user.getCurrentUser().role.id === 3">
            <div
              @click="tab = 0"
              class="mb-1 rounded d-flex align-center px-3 font-weight-medium text-uppercase"
              :class="
                tab === 0 ? 'yellow darken-3 white--text' : 'primary--text'
              "
              style="height:36px; cursor:pointer"
              :style="tab === 0 ? '' : 'background-color: rgb(242, 247, 253);'"
            >
              {{ $t("QUES.allQ") }}
            </div>
            <div
              @click="tab = 1"
              class="mb-1 rounded d-flex align-center px-3 font-weight-medium text-uppercase"
              :class="
                tab === 1 ? 'yellow darken-3 white--text' : 'primary--text'
              "
              style="height:36px; cursor:pointer"
              :style="tab === 1 ? '' : 'background-color: rgb(242, 247, 253);'"
            >
              {{ $t("QUES.tabs.byme") }}
            </div>

            <div v-if="tab === 0" style="min-width: fit-content" class="">
              <div class="primary--text mt-3 text-headline font-weight-bold">
                {{ $t("QUES.filter") }}
              </div>
            </div>

            <v-chip-group column :key="tab" v-if="tab === 0" v-model="category">
              <v-chip
                small
                color="#d2e1f2"
                class="primary--text"
                active-class="white--text yellow darken-3"
                filter
                v-for="item in statusfilters"
                :key="item.id"
              >
                {{ item.name }}
              </v-chip>
            </v-chip-group>
          </template>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        :class="
          $vuetify.breakpoint.mobile
            ? 'ma-0 pa-0 overflow-x-hidden'
            : 'my-0 py-0'
        "
      >
        <v-expand-transition>
          <v-card
            :disabled="isloading"
            class="transparent pa-0 ma-0"
            flat
            v-show="showcontroles && $vuetify.breakpoint.mobile"
          >
            <template v-if="$user.getCurrentUser().role.id === 4">
              <!-- mobile tabs -->
              <v-btn-toggle
                v-model="tab"
                mandatory
                dense
                style="width: 100%"
                class="mx-0 blue primary--text rounded-lg"
              >
                <v-btn
                  active-class="activebtn"
                  width="50%"
                  class="primary--text"
                >
                  {{ $t("QUES.tabs.explore") }}
                </v-btn>
                <v-btn
                  active-class="activebtn"
                  width="50%"
                  class="primary--text"
                >
                  {{ $t("QUES.myQ") }}
                </v-btn>
              </v-btn-toggle>

              <!-- mobile filter - user -->
              <div class="d-flex align-center">
                <div style="min-width: fit-content" class="pushx primary--text">
                  {{ $t("QUES.filter") }}
                </div>
                <v-chip-group :key="tab" v-if="tab === 0" v-model="category">
                  <v-chip
                    small
                    color="#d2e1f2"
                    class="primary--text"
                    active-class="white--text yellow darken-3"
                    filter
                    v-for="item in departments"
                    :key="item.id"
                  >
                    <v-avatar
                      style="
                    min-width: 18px !important;
                    min-height: 18px !important;
                  "
                      height="18px"
                      width="18px"
                      max-height="18px"
                      max-width="18px"
                      color="white"
                      left
                    >
                      <v-img
                        contain
                        height="10"
                        v-if="item.avatar"
                        :src="asseturl + item.avatar.private_hash"
                      ></v-img>
                      <div
                        v-else
                        style="text-transform: uppercase; width: 100%"
                        class="primary--text font-size-h4"
                      >
                        {{ item.name.charAt(0) }}
                      </div>
                    </v-avatar>
                    {{ $util.getTranslation(item).name }}
                  </v-chip>
                </v-chip-group>
                <v-chip-group :key="tab" v-else v-model="category">
                  <v-chip
                    small
                    color="#d2e1f2"
                    class="primary--text"
                    active-class="white--text yellow darken-3"
                    filter
                    v-for="item in statusfilters"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </v-chip>
                </v-chip-group>
              </div>
            </template>
            <template v-else-if="$user.getCurrentUser().role.id === 3">
              <!-- mobile filter - doctor -->
              <v-btn-toggle
                v-model="tab"
                mandatory
                borderless
                dense
                style="width: 100%"
                class="mx-0 mb-2 blue primary--text rounded-lg"
              >
                <v-btn
                  active-class="activebtn"
                  width="50%"
                  class="primary--text"
                >
                  {{ $t("QUES.allQ") }}
                </v-btn>
                <v-btn
                  active-class="activebtn"
                  width="50%"
                  class="primary--text"
                >
                  {{ $t("QUES.tabs.byme") }}
                </v-btn>
              </v-btn-toggle>
              <div class="d-flex align-center">
                <div
                  v-if="tab === 0"
                  style="min-width: fit-content"
                  class="pushx primary--text"
                >
                  {{ $t("QUES.filter") }}
                </div>

                <v-chip-group :key="tab" v-if="tab === 0" v-model="category">
                  <v-chip
                    small
                    color="#d2e1f2"
                    class="primary--text"
                    active-class="white--text yellow darken-3"
                    filter
                    v-for="item in statusfilters"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </v-chip>
                </v-chip-group>
              </div>
            </template>
          </v-card>
        </v-expand-transition>
        <v-virtual-scroll
          v-if="!isloadingsection"
          :bench="20"
          :items="filteredlist"
          :height="
            $vuetify.breakpoint.mobile
              ? 'calc(100vh - 120px)'
              : 'calc(100vh - 130px)'
          "
          item-height="90"
          class="bg-transparent hidescroll"
          @scroll="onscroll"
        >
          <template v-slot:default="{ item }">
            <div
              class="mb-1 listitem rounded-lg"
              v-intersect="onIntersect(item.id)"
            >
              <v-list-item
                style="min-height: 80px"
                :key="item.id"
                :style="!$vuetify.breakpoint.mobile ? 'min-width:40vw' : ''"
              >
                <v-list-item-avatar
                  @click="
                    selected = item;
                    showmodal = true;
                  "
                  size="47"
                  color="#F2F7FD"
                >
                  <v-img
                    v-if="item.department.avatar != null"
                    height="25"
                    contain
                    :src="asseturl + item.department.avatar.private_hash"
                  ></v-img>
                  <div
                    v-else
                    style="text-transform: uppercase; width: 100%"
                    class="primary--text font-size-h4"
                  >
                    {{ item.department.name.charAt(0) }}
                  </div>
                </v-list-item-avatar>
                <v-list-item-content
                  @click="
                    selected = item;
                    showmodal = true;
                  "
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle class="my-2">{{
                    item.question
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    >{{
                      item.created_on
                        | moment(
                          "timezone",
                          $util.getTimezone(),
                          "ddd DD/MM/YYYY hh:mm A"
                        )
                    }}
                    {{
                      item.created_on
                        | moment("timezone", $util.getTimezone(), "from", "now")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action
                  v-if="tab === 1 && $user.getCurrentUser().role.id === 4"
                >
                  <v-btn
                    @click="
                      todelete = item.id;
                      deletedialg = true;
                    "
                    small
                    fab
                    elevation="0"
                    color="rgba(239, 165, 179, 0.25)"
                  >
                    <unicon
                      style="margin-right: auto; margin-left: auto"
                      name="trash-alt"
                      fill="#f64e60"
                      height="20"
                    ></unicon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </div>
          </template>
        </v-virtual-scroll>
        <div
          v-else
          class="d-flex flex-column justify-center align-center"
          style="height: calc(100vh - 220px)"
          :style="!$vuetify.breakpoint.mobile ? 'min-width:40vw' : ''"
        >
          <v-progress-circular indeterminate color="primary" size="40" />
        </div>
        <v-scroll-y-reverse-transition>
          <div
            class="d-flex justify-center align-center"
            style="width:100%;max-height:40px"
          >
            <v-btn
              v-show="isloading && !isloadingsection"
              style="z-index: 1;position: fixed;bottom: calc(env(Safe-area-inset-bottom, 40px) + 30px);"
              rounded
              elevation="0"
              color="primary"
              dark
            >
              {{ $t("GENERAL.loading") }}
            </v-btn>
          </div>
        </v-scroll-y-reverse-transition>
      </v-col>
    </v-row>
    <v-scroll-y-reverse-transition>
      <v-btn
        v-if="$user.getCurrentUser().role.id === 4"
        @click="$refs.add.open()"
        v-show="showcontroles"
        :style="$root.lang === 'ar' ? 'left: 10px;' : 'right: 10px;'"
        style="
          z-index: 1;
          position: fixed;
          bottom: calc(env(Safe-area-inset-bottom, 40px) + 30px);
        "
        fab
        elevation="0"
        color="primary"
        dark
      >
        <v-icon ref="addicon" class="" color="white">mdi-plus</v-icon>
      </v-btn>
    </v-scroll-y-reverse-transition>
    <v-bottom-sheet
      :width="$vuetify.breakpoint.mobile ? '' : '45vw'"
      scrollable
      v-model="showmodal"
    >
      <v-card v-if="selected" flat>
        <v-toolbar flat dark color="white" class="text-primary">
          <v-toolbar-title class="d-flex align-center"
            ><div class="font-weight-bold">{{ selected.title }}</div>
            <div class="px-2" style="font-size: 0.75em">
              {{ selected.created_on | moment("from", "now") }}
            </div></v-toolbar-title
          >
          <v-spacer />
          <v-btn
            icon
            dark
            color="primary"
            @click="
              showmodal = false;
              selected = null;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <question-details
          class="px-3"
          :question="selected"
          @close="handledetailsclose"
        />
      </v-card>
    </v-bottom-sheet>
    <add-question ref="add" />
    <v-dialog v-model="deletedialg" max-width="290" persistent>
      <v-card :loading="isdeleting" :disabled="isdeleting" class="rounded-lg">
        <v-card-title class="text-h5">
          {{ $t("QUES.delete.warn.title") }}
        </v-card-title>

        <v-card-text style="font-size: 1.1rem">
          {{ $t("QUES.delete.warn.body") }}
        </v-card-text>

        <v-card-actions style="border-top: solid 1px lightgray" class="pa-0">
          <v-row style="width: 100%" class="pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <v-btn
                color="primary darken-1"
                text
                class="elevation-0"
                block
                large
                @click="
                  todelete = null;
                  deletedialg = false;
                "
              >
                {{ $t("GENERAL.cancel") }}
              </v-btn>
            </v-col>
            <v-col
              class="pa-0 ma-0"
              :style="
                $root.lang === 'ar'
                  ? 'border-right: solid 1px lightgray'
                  : 'border-left: solid 1px lightgray'
              "
            >
              <v-btn
                color="red darken-1"
                class="elevation-0"
                text
                block
                large
                @click="deleteItem()"
              >
                {{ $t("GENERAL.delete") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import QuestionDetails from "./comp/details.vue";
import AddQuestion from "./comp/new.vue";
export default {
  name: "advice",
  components: { QuestionDetails, AddQuestion },
  data() {
    return {
      items: [],
      asseturl: process.env.VUE_APP_api_url + "assets/",
      options: this.$question.getOptions(),
      loadnewpageat: -1,
      page: 1,
      limit: 0,
      isloading: false,
      isloadingsection: true,
      category: null,
      tab: 0,
      showcontroles: true,
      offsettop: 0,
      showmodal: false,
      todelete: null,
      deletedialg: false,
      isdeleting: false,
      selected: null,
      statusfilters: [
        { id: 0, name: this.$t("QUES.tabs.answered") },
        { id: 1, name: this.$t("QUES.tabs.notanswered") }
      ]
    };
  },
  beforeMount() {},
  created() {
    // this.getItems();
  },
  computed: {
    departments() {
      return this.$department.data.departments.data.filter(department => {
        return department.doctors.length > 0;
      });
    },
    filteredlist() {
      if (this.tab === 0) {
        if (this.$user.getCurrentUser().role.id === 4) {
          if (this.category === null || this.category == undefined) {
            // // console.log("category is empty return all items");
            return this.items;
          } else {
            /* // console.log(
            "category is",
            this.category,
            "depart is",
            this.$department.data.departments.data[this.category]
          ); */
            // this.loadnewcategorypage();
            return this.items.filter(item => {
              return item.department.id === this.departments[this.category].id;
            });
          }
        } else {
          if (this.category === 0)
            return this.items.filter(item => {
              return item.status === "answered";
            });
          else if (this.category === 1)
            return this.items.filter(item => {
              return item.status === "notanswered";
            });
          else return this.items;
        }
      } else {
        // // console.log("we are in owned");
        if (this.$user.getCurrentUser().role.id === 4) {
          if (this.category === 0) {
            return this.items.filter(item => {
              return item.status === "answered";
            });
          } else if (this.category === 1) {
            return this.items.filter(item => {
              return item.status !== "answered";
            });
          } else
            return this.items.filter(item => {
              return item.owner.id === this.$user.getCurrentUser().id;
            });
        } else if (this.$user.getCurrentUser().role.id === 3) {
          return this.items.filter(item => {
            return (
              item.status === "answered" &&
              item.doctor.doctor_id &&
              item.doctor.doctor_id.id === this.$doctor.getCurrentDoctor().id
            );
          });
        } else return this.items;
      }
    }
  },
  watch: {
    offsettop(news, olds) {
      if (news <= 0) {
        this.showcontroles = true;
      } else if (olds < news) this.showcontroles = false;
      else this.showcontroles = true;
    },
    category() {
      // // console.log("------- firing from category")
      this.updateOptions();
      this.getItems();
    },
    tab() {
      this.category = null;
      this.isloadingsection = true;

      // // console.log("------- firing from tab")
      this.updateOptions();
      this.getItems();
    }
  },
  mounted() {
    this.$root.title = this.$t("MENU.questions");
    this.$root.currentpage = this.$route.name;
    this.updateOptions();
    // // console.log("------- firing from mounted")
    this.getItems();
  },
  methods: {
    handledetailsclose(e) {
      if (e) {
        this.showmodal = false;
        let index = this.items.findIndex(item => {
          return item.id === this.selected.id;
        });
        this.items[index].answer = e;
        this.items[index].status = "answered";
      }
    },
    updateOptions() {
      this.options = this.$question.resetOptions();
      // // console.log("update options");
      if (this.$user.getCurrentUser().role.id === 3) {
        this.options.filter.department = {
          operator: "eq",
          value: this.$doctor.getCurrentDoctor().department.id
        };
        if (this.tab === 0) {
          if (this.category === 0)
            this.options.filter.status = { operator: "eq", value: "answered" };
          else if (this.category === 1)
            this.options.filter.status = {
              operator: "eq",
              value: "notanswered"
            };
        } else if (this.tab === 1) {
          this.options.filter.status = { operator: "eq", value: "answered" };
          this.options.filter.doctor = {
            operator: "eq",
            value: this.$doctor.getCurrentDoctor().id
          };
        }
      } else if (this.$user.getCurrentUser().role.id === 4) {
        if (this.tab === 0) {
          this.options.filter.status = { operator: "eq", value: "answered" };
          if (
            this.category !== null &&
            this.category !== undefined &&
            this.category >= 0
          ) {
            this.options.filter.department = {
              operator: "eq",
              value: this.departments[this.category].id
            };
          }
        } else {
          this.options.filter.owner = {
            operator: "eq",
            value: this.$user.getCurrentUser().id
          };
        }
      }
      // // console.log("updated options", this.options);
    },
    async getItems() {
      if (!this.isloading) {
        this.isloading = true;
        // console.log("in get items");

        // // console.log("loading new page", this.page);
        await this.$question.getItems(this.options).then(data => {
          // console.log("new page data", data);
          this.limit = data.data.meta.page_count;

          // // console.log("new items", this.items);
          if (data.data.data.length !== 0) {
            console.log("data is grater than 0", this.filteredlist);
            this.items = this.$util.merge(data.data.data, this.filteredlist);
            this.loadnewpageat =
              data.data.data.length > 10
                ? this.items[this.items.length - 10].id
                : this.items[this.items.length - 1].id;
          } else {
            this.loadnewpageat = -1;
          }
          this.options.page++;
          this.isloading = false;
          this.isloadingsection
            ? (this.isloadingsection = !this.isloadingsection)
            : "";
        });
      }
    },
    async deleteItem() {
      // // console.log("item to be deleted", this.todelete);
      this.isdeleting = true;
      await this.$question.deleteItem(this.todelete).then(res => {
        if (res.status === 204) {
          // // console.log("delete successful, remove from list", res);
          this.isdeleting = false;
          this.deletedialg = false;
          let index = this.items.findIndex(item => {
            return this.todelete === item.id;
          });
          this.items.splice(index, 1);
          this.todelete = null;
        } else {
          // // console.log("delete error");
          this.isdeleting = false;
        }
      });
    },
    onscroll(e) {
      this.offsettop = e.target.scrollTop;
    },
    async onIntersect(id) {
      /* // console.log(
        "item shown is",
        id,
        "threshold is",
        this.loadnewpageat,
        "limit",
        this.limit,
        "page",
        this.options.page
      ); */
      if (
        id === this.loadnewpageat &&
        this.loadnewpageat > 0 &&
        this.options.page <= this.limit
      ) {
        // // console.log("------- firing from intercect")
        this.getItems();
      }
    },
    merge(array1, array2) {
      /*// console.log(
        "array 1 (new data)",
        array1,
        array1.length,
        "array2",
        array2
      );*/
      if (array1.length == 0 || array1 === undefined) {
        // console.log("merge to return 1",array2)
        return array2;
      } else if (array2.length == 0 || array2 === undefined) {
        // console.log("merge to return 2",array1)
        return array1;
      }
      let i = 0;
      while (array1.length > 0 && i < array2.length) {
        // console.log("in loop",'array1 length',array1.length, 'i is', i)
        if (array1[0].id === array2[i].id) {
          // console.log("shifting",'array1 length' ,array1.length ,array1[0].id, array2[i].id);
          array1.shift();
        }
        i++;
      }
      // console.log("after shifting", array1, array2);

      return array2.concat(array1);
    }
  }
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-application--wrap {
  min-height: 0px !important;
}
.activebtn {
  border-style: solid;
  border-color: #d2e1f2 !important;
  background: white !important;
  border-width: 4px !important;
  color: #0971ce !important;
}
</style>
<style scoped>
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) >>> .v-btn.v-btn {
  border-color: #d2e1f2 !important;
  border-width: 0px;
}

.v-btn:before {
  background-color: transparent !important;
}
</style>
