<template>
  <div class="chatarea" v-if="question">
    <!-- question -->
    <div class="d-flex">
      <v-avatar class="pushx" size="30" color="#F2F7FD">
        <v-img
          v-if="
            question.owner.avatar &&
              $user.getCurrentUser().id === question.owner.id
          "
          :src="$root.asseturl + question.owner.avatar.private_hash"
        />
        <div
          v-else-if="$user.getCurrentUser().id === question.owner.id"
          style="text-transform: uppercase; width: 100%"
          class="primary--text font-size-h4"
        >
          {{ question.owner.first_name.charAt(0) }}
        </div>
        <div
          v-else
          style="text-transform: uppercase; width: 100%"
          class="primary--text font-size-h4"
        >
          D
        </div>
      </v-avatar>
      <div class="msg mb-3" :class="'local primary'">
        {{ question.question }}
      </div>
    </div>

    <!-- answer -->
    <div
      v-if="question.status == 'answered'"
      class="d-flex justify-center mb-3 primary--text"
    >
      <span
        v-if="question.doctor.doctor_id"
        v-html="
          $t('QUES.answer.by', {
            name: `${question.doctor.doctor_id.first_name} ${question.doctor.doctor_id.last_name}`
          })
        "
      >
      </span>
      <span v-else v-html="$t('QUES.answer.default')"> </span>
    </div>
    <div v-if="question.status == 'answered'" class="d-flex justify-end">
      <v-btn
        v-if="
          $user.getCurrentUser().role.id === 3 &&
            question.doctor.doctor_id.id === $doctor.getCurrentDoctor().id
        "
        class="mx-1"
        small
        fab
        height="30px"
        width="30px"
        elevation="0"
        color="rgba(165, 203, 239, 0.20)"
        @click="doedit = !doedit"
      >
        <unicon
          style="margin-right: auto;margin-left: auto;"
          name="edit-alt"
          fill="#0971CE"
          height="15"
        ></unicon>
      </v-btn>
      <div class="msg mb-3 pushx" :class="'remote green'">
        {{ question.answer }}
      </div>
      <v-avatar class="" size="30" color="#F2F7FD">
        <v-img
          v-if="question.doctor.doctor_id && question.doctor.doctor_id.avatar"
          :src="$root.asseturl + question.doctor.doctor_id.avatar.private_hash"
        />

        <div
          v-else
          style="text-transform: uppercase; width: 100%"
          class="primary--text font-size-h4"
        >
          <span v-if="question.doctor.doctor_id">{{
            question.doctor.doctor_id.first_name.charAt(0)
          }}</span>
          <span v-else>D</span>
        </div>
      </v-avatar>
    </div>

    <!-- doctor new answersection -->
    <template v-if="$user.getCurrentUser().role.id === 3">
      <div v-if="question.status == 'notanswered' || doedit" class="mb-2">
        <v-form v-model="valid">
          <v-textarea
            auto-grow
            :rules="detailsRules"
            rows="2"
            solo
            flat
            label="Write your answer here, you can edit it later"
            v-model="answer"
            class="mb-2"
          />
          <v-btn
            @click="postAnswer"
            :disabled="issending || !valid"
            :loading="issending"
            rounded
            color="green"
            class="white--text"
            block
            >Post Answer</v-btn
          >
        </v-form>
      </div>
    </template>

    <!-- rating -->
    <div
      v-if="question.rating"
      class="d-flex flex-column mb-5 align-items-start"
    >
      <v-rating :value="question.rating" readonly></v-rating>
    </div>
  </div>
</template>

<script>
//import ApiService from "@/core/services/api.service";

export default {
  name: "QuestionsDetails",
  props: ["question"],
  components: {},
  data() {
    return {
      valid: false,
      answer: "",
      issending: false,
      doedit: false,
      detailsRules: [
        v => !!v || this.$t("QUES.validation.required"),
        v => (v && v.trim().length >= 10) || this.$t("QUES.validation.length")
      ]
    };
  },
  watch: {
    doedit(updated) {
      if (updated) {
        this.answer = this.question.answer;
      }
    }
  },
  beforeMount() {},
  mounted() {},
  methods: {
    async postAnswer() {
      this.issending = true;
      await this.$question
        .postAnswer(this.question.id, {
          answer: this.answer,
          status: "answered",
          doctor: this.$doctor.getCurrentDoctor().id
        })
        .then(res => {
          if (res.status === 200) {
            this.issending = false;
            this.$emit("close", this.answer);
            this.doedit = false;
          }
        });
    }
  }
};
</script>
